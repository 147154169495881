@import "./colors";

.grid {
  border: 5px solid $color--cell-border;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 1fr);
  height: 33em;
  width: 33em;
}

.puzzle {
  cursor: pointer;
  font-size: 14px;
  margin-top: 1em;
  overflow-wrap: break-word;
}
