@import "./colors";

.cell {
  border-bottom: 1px solid $color--cell-border;
  border-right: 1px solid $color--cell-border;
  cursor: pointer;
  display: grid;
  padding: 0.1em;
  transition: background 0.3s ease-in-out;

  .notes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .item {
      color: $color--cell-notes;
      font-size: 12px;
      place-self: center;
    }

    &.hide {
      display: none;
    }
  }

  .value {
    font-size: 32px;
    font-weight: 400;
    place-self: center;

    &.hide {
      display: none;
    }
  }

  &.hover {
    .notes {
      display: none;
    }

    .value {
      color: $color--cell-error;
      display: block;
    }
  }

  .success & {
    background: rgba($color--cell-sucess, 0.6);

    &.current {
      background: rgba($color--cell-sucess, 0.6);
    }
  }

  &.current {
    background: rgba($color--cell-background, 0.4);
  }

  .error &,
  &.error {
    background: rgba($color--cell-error, 0.6);

    &.current {
      background: rgba($color--cell-error, 0.8);
    }
  }

  &.predefined {
    background: rgba($color--cell-background, 0.6);

    &.current {
      background: rgba($color--cell-background, 0.8);
    }
  }

  &:nth-child(3n) {
    border-right: 3px solid $color--cell-border;
  }

  &:nth-child(n + 19):nth-child(-n + 27),
  &:nth-child(n + 46):nth-child(-n + 54),
  &:nth-child(n + 73) {
    border-bottom: 3px solid $color--cell-border;
  }

  &:nth-child(9n) {
    border-right: 0;
  }

  &:nth-child(n + 73) {
    border-bottom: 0;
  }
}
